<script setup lang="ts">
useHead({
  link: [{ rel: 'icon', type: 'image/png', href: 'favicon.ico' }]
})
</script>

<template>
  <div>
    <NuxtLayout>
      <NuxtLoadingIndicator />
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
