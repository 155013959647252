import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45maintenance_45global from "/vercel/path0/middleware/01.maintenance.global.ts";
import _02_45auth_45global from "/vercel/path0/middleware/02.auth.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45maintenance_45global,
  _02_45auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  jobs: () => import("/vercel/path0/middleware/jobs.ts"),
  push: () => import("/vercel/path0/middleware/push.ts"),
  tour: () => import("/vercel/path0/middleware/tour.ts"),
  users: () => import("/vercel/path0/middleware/users.ts"),
  wallet: () => import("/vercel/path0/middleware/wallet.ts")
}