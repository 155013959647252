import VueGoogleMaps from 'vue-google-maps-community-fork'

export default defineNuxtPlugin(nuxtApp => {
  const config = useRuntimeConfig()

  nuxtApp.vueApp.use(VueGoogleMaps, {
    load: {
      key: config.public.GOOGLE_MAPS_API_KEY,
      language: 'de',
      region: 'DE',
      version: '3.42'
    }
  })
})
