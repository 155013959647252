import VueMatomo from 'vue-matomo'

export default defineNuxtPlugin(nuxtApp => {
  const config = useRuntimeConfig()
  const siteId = Number.parseInt(config.public.SITE_ID)

  const authStore = useAuthStore()
  const user = authStore.user

  nuxtApp.vueApp.use(VueMatomo, {
    host: 'https://analytics.mammut-hosting.de',
    siteId,
    router: nuxtApp.$router,
    enableLinkTracking: true,
    enableHeartBeatTimer: true,
    userId: user ? user.id : null,
    requireConsent: false,
    trackInitialView: true,
    disableCookies: true,
    requireCookieConsent: false
  })
})
