// @ts-ignore
import Parse from 'parse/dist/parse.min'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const id: string = config.public.ApplicationId
  const secret: string = config.public.JavascriptKey
  const serverUrl: string = config.public.ServerUrl

  Parse.initialize(id, secret)
  Parse.serverURL = serverUrl
  return {
    provide: {
      Parse
    }
  }
})
