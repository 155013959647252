export default defineNuxtRouteMiddleware(async to => {
  const { $Parse } = useNuxtApp()
  const rtConfig = useRuntimeConfig()
  let maintenance = true

  try {
    const config = await $Parse.Config.get()
    maintenance = config.get('maintenance')
  } catch (error: any) {
    if (error.code === 209) {
      maintenance = false
    }
  }

  if (
    (maintenance || rtConfig.public.MAINTENANCE_MODE === 'true') &&
    to.path !== '/auth/maintenance'
  ) {
    return navigateTo('/auth/maintenance')
  }

  if (
    !maintenance &&
    rtConfig.public.MAINTENANCE_MODE === 'false' &&
    to.path === '/auth/maintenance'
  ) {
    return navigateTo('/')
  }
})
