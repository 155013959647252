import { defineStore } from 'pinia'

export const useAuthStore = defineStore({
  id: 'authStore',
  state: () => ({
    user: undefined as Parse.User<Parse.Attributes> | undefined,
    lastCheck: new Date(0),
    roles: []
  }),

  getters: {},
  actions: {
    fetchUser() {
      const { $Parse } = useNuxtApp()
      const user = $Parse.User.current()
      this.user = user
      this.lastCheck = new Date()
    },
    async fetchRoles() {
      const { $Parse } = useNuxtApp()
      const roles = await $Parse.Cloud.run('dashboard-user-roles')
      this.roles = roles
    },
    async logout() {
      const { $Parse } = useNuxtApp()
      await $Parse.User.logOut()
      this.user = undefined
      this.roles = []
      navigateTo('/auth/login')
    },
    SET_USER(user: any) {
      this.user = user
    },
    SET_ROLES(roles: any) {
      this.roles = roles
    }
  }
})
