export default defineNuxtRouteMiddleware(async (to, from) => {
  const { $Parse } = useNuxtApp()
  const authStore = useAuthStore()
  let user = authStore.user
  let roles = authStore.roles
  const lastCheck = authStore.lastCheck

  try {
    const session = await $Parse.Session.current()
    if (session) {
      await session.fetch()
    }
  } catch (error: any) {
    if (error.code === 209) {
      await $Parse.User.logOut()
      user = undefined
      roles = []

      authStore.SET_USER(user)
    }
  }

  if (!user) {
    user = $Parse.User.current()
    authStore.SET_USER(user)

    if (user) {
      try {
        roles = await $Parse.Cloud.run('dashboard-user-roles')
        authStore.SET_ROLES(roles)
      } catch {
        return navigateTo(from.fullPath)
      }
    }
  }

  // If the user is logged in and the last check was more than 5 minutes ago, fetch the user and roles again
  if (user && lastCheck.getTime() < Date.now() - 1000 * 60 * 5) {
    authStore.fetchUser()
    authStore.fetchRoles()
  }

  if (to.path === '/auth/forgot-password') {
    return
  }

  if (user && to.path === '/auth/login') {
    return navigateTo('/')
  }

  if (!user && to.path !== '/auth/login') {
    return navigateTo('/auth/login')
  }

  // if (user && to.path === '/') {
  //   return navigateTo('/')
  // }
})
