import { default as _404dtpnFF7sLmMeta } from "/vercel/path0/pages/auth/404.vue?macro=true";
import { default as Forgot_45PasswordFR4QyoDeNsMeta } from "/vercel/path0/pages/auth/Forgot-Password.vue?macro=true";
import { default as Login716SBvoAAxMeta } from "/vercel/path0/pages/auth/Login.vue?macro=true";
import { default as MaintenancesDi2loJwCnMeta } from "/vercel/path0/pages/auth/Maintenance.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as index4iqCgT2ECDMeta } from "/vercel/path0/pages/jobs/index.vue?macro=true";
import { default as indexSsrwCXeuTQMeta } from "/vercel/path0/pages/profile/index.vue?macro=true";
import { default as _91id_93L9XJzHzWvRMeta } from "/vercel/path0/pages/push/[id].vue?macro=true";
import { default as indexIuycchl1l4Meta } from "/vercel/path0/pages/push/audiences/index.vue?macro=true";
import { default as index0wBvvVXCpGMeta } from "/vercel/path0/pages/push/index.vue?macro=true";
import { default as indexnhg1XU2fvFMeta } from "/vercel/path0/pages/push/send/index.vue?macro=true";
import { default as _91id_930PNNnl7sA7Meta } from "/vercel/path0/pages/tours/[id].vue?macro=true";
import { default as _91id_93xhBjGrRCYZMeta } from "/vercel/path0/pages/tours/categories/[id].vue?macro=true";
import { default as indexOOnaryyXOfMeta } from "/vercel/path0/pages/tours/categories/index.vue?macro=true";
import { default as indexemJKwxSY7PMeta } from "/vercel/path0/pages/tours/index.vue?macro=true";
import { default as indexOXtObJcgq9Meta } from "/vercel/path0/pages/users/index.vue?macro=true";
import { default as indextxLm2mB18vMeta } from "/vercel/path0/pages/wallet/cards/index.vue?macro=true";
import { default as indexjN5vUElvc8Meta } from "/vercel/path0/pages/wallet/templates/index.vue?macro=true";
import { default as _91id_930dFKrdMRf7Meta } from "/vercel/path0/pages/wallet/types/[id].vue?macro=true";
import { default as indexzPc730VGPIMeta } from "/vercel/path0/pages/wallet/types/index.vue?macro=true";
export default [
  {
    name: "auth-404",
    path: "/auth/404",
    meta: _404dtpnFF7sLmMeta || {},
    component: () => import("/vercel/path0/pages/auth/404.vue").then(m => m.default || m)
  },
  {
    name: "auth-Forgot-Password",
    path: "/auth/Forgot-Password",
    meta: Forgot_45PasswordFR4QyoDeNsMeta || {},
    component: () => import("/vercel/path0/pages/auth/Forgot-Password.vue").then(m => m.default || m)
  },
  {
    name: "auth-Login",
    path: "/auth/Login",
    meta: Login716SBvoAAxMeta || {},
    component: () => import("/vercel/path0/pages/auth/Login.vue").then(m => m.default || m)
  },
  {
    name: "auth-Maintenance",
    path: "/auth/Maintenance",
    meta: MaintenancesDi2loJwCnMeta || {},
    component: () => import("/vercel/path0/pages/auth/Maintenance.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "jobs",
    path: "/jobs",
    meta: index4iqCgT2ECDMeta || {},
    component: () => import("/vercel/path0/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/vercel/path0/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "push-id",
    path: "/push/:id()",
    meta: _91id_93L9XJzHzWvRMeta || {},
    component: () => import("/vercel/path0/pages/push/[id].vue").then(m => m.default || m)
  },
  {
    name: "push-audiences",
    path: "/push/audiences",
    meta: indexIuycchl1l4Meta || {},
    component: () => import("/vercel/path0/pages/push/audiences/index.vue").then(m => m.default || m)
  },
  {
    name: "push",
    path: "/push",
    meta: index0wBvvVXCpGMeta || {},
    component: () => import("/vercel/path0/pages/push/index.vue").then(m => m.default || m)
  },
  {
    name: "push-send",
    path: "/push/send",
    meta: indexnhg1XU2fvFMeta || {},
    component: () => import("/vercel/path0/pages/push/send/index.vue").then(m => m.default || m)
  },
  {
    name: "tours-id",
    path: "/tours/:id()",
    meta: _91id_930PNNnl7sA7Meta || {},
    component: () => import("/vercel/path0/pages/tours/[id].vue").then(m => m.default || m)
  },
  {
    name: "tours-categories-id",
    path: "/tours/categories/:id()",
    meta: _91id_93xhBjGrRCYZMeta || {},
    component: () => import("/vercel/path0/pages/tours/categories/[id].vue").then(m => m.default || m)
  },
  {
    name: "tours-categories",
    path: "/tours/categories",
    meta: indexOOnaryyXOfMeta || {},
    component: () => import("/vercel/path0/pages/tours/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "tours",
    path: "/tours",
    meta: indexemJKwxSY7PMeta || {},
    component: () => import("/vercel/path0/pages/tours/index.vue").then(m => m.default || m)
  },
  {
    name: "users",
    path: "/users",
    meta: indexOXtObJcgq9Meta || {},
    component: () => import("/vercel/path0/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet-cards",
    path: "/wallet/cards",
    meta: indextxLm2mB18vMeta || {},
    component: () => import("/vercel/path0/pages/wallet/cards/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet-templates",
    path: "/wallet/templates",
    meta: indexjN5vUElvc8Meta || {},
    component: () => import("/vercel/path0/pages/wallet/templates/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet-types-id",
    path: "/wallet/types/:id()",
    meta: _91id_930dFKrdMRf7Meta || {},
    component: () => import("/vercel/path0/pages/wallet/types/[id].vue").then(m => m.default || m)
  },
  {
    name: "wallet-types",
    path: "/wallet/types",
    meta: indexzPc730VGPIMeta || {},
    component: () => import("/vercel/path0/pages/wallet/types/index.vue").then(m => m.default || m)
  }
]